import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SettingsSidebar from './SettingsSidebar';
import Header from './Header';
import './SettingsPage.css';
import * as XLSX from 'xlsx';  // Import XLSX for log file creation

const SettingsPage = () => {
    const defaultSettings = {
        llmProvider: 'OpenAI',
        model: 'gpt-4o-mini',
        temperature: 0.5,
        maxTokens: 2500,
        url: 'https://api.openai.com/v1/chat/completions',
    };

    const loadSettingsFromLocalStorage = () => {
        const savedSettings = localStorage.getItem('llmSettings');
        return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
    };

    const [settings, setSettings] = useState(loadSettingsFromLocalStorage());
    const [prompts, setPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [activeTab, setActiveTab] = useState('Main');
    const [newPromptName, setNewPromptName] = useState('');
    const [newPromptText, setNewPromptText] = useState('');

    // New state for Prompt Pipeline
    const [pipelineName, setPipelineName] = useState('');
    const [pipelinePrompts, setPipelinePrompts] = useState([]);
    const [pipelines, setPipelines] = useState('');

    const [tempSliderValue, setTempSliderValue] = useState(settings.temperature);
    const [maxTokensSliderValue, setMaxTokensSliderValue] = useState(settings.maxTokens);
    // Add these lines at the top with other state definitions to declare selectedPipeline
    const [selectedPipeline, setSelectedPipeline] = useState(null);

    const providerModelMap = {
        OpenAI: [
            { name: 'gpt-4o-mini', url: 'https://api.openai.com/v1/chat/completions', model_max_tokens: '16384' },
            { name: 'gpt-4o', url: 'https://api.openai.com/v1/chat/completions', model_max_tokens: '16384'  },
            { name: 'o1-preview', url: 'https://api.openai.com/v1/chat/completions', model_max_tokens: '32768'  },
            { name: 'o1-mini', url: 'https://api.openai.com/v1/chat/completions', model_max_tokens: '65536'  },
            { name: 'o3-mini', url: 'https://api.openai.com/v1/chat/completions', model_max_tokens: '100000'  }
        ],
        Anthropic: [
            { name: 'claude-3-5-sonnet', url: 'https://api.anthropic.com/v1/messages', model_max_tokens: '32000'  }
        ],
        OpenSource: [
            { name: 'deepseek-r1:32b', url: 'http://192.168.69.74:11434/api/generate' , model_max_tokens: '32000' },
            { name: 'mistral-small:24b', url: 'http://192.168.69.74:11434/api/generate', model_max_tokens: '32000'  }
        ]
    };

    // State to manage the maximum token value for the selected model
    const [maxTokenValue, setMaxTokenValue] = useState(16000);

    useEffect(() => {
        if (activeTab === 'Prompts' || activeTab === 'Prompt Pipelines') {
            axios.get('https://r3-pearl.ddns.net/api/prompts')
                .then(response => {
                    setPrompts(response.data);
                })
                .catch(error => console.error('Error fetching prompts:', error));
        }
    }, [activeTab]);

    useEffect(() => {
        // Set maxTokens based on the selected model
        const currentProviderModels = providerModelMap[settings.llmProvider];
        const currentModel = currentProviderModels.find(model => model.name === settings.model);
        if (currentModel) {
            setMaxTokenValue(parseInt(currentModel.model_max_tokens));
            setMaxTokensSliderValue(prevMaxTokensSliderValue => {
                return Math.min(prevMaxTokensSliderValue, parseInt(currentModel.model_max_tokens));
            });
        }
    }, [settings.model, settings.llmProvider]);

    const handleSettingChange = (key, value) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [key]: value
        }));
        console.log(`Updated settings: ${key} = ${value}`);
    };

    const handleSelectPrompt = (event) => {
        const promptId = event.target.value;
        const prompt = prompts.find(p => p.id.toString() === promptId);
        setSelectedPrompt(prompt || null);
        setNewPromptName(prompt ? prompt.prompt_name : '');
        setNewPromptText(prompt ? prompt.prompt_text : '');
    };

    const handleAddToPipeline = () => {
        if (selectedPrompt && !pipelinePrompts.includes(selectedPrompt)) {
            setPipelinePrompts([...pipelinePrompts, selectedPrompt]);
        }
    };

    const handleRemoveFromPipeline = (prompt) => {
        setPipelinePrompts(pipelinePrompts.filter(p => p !== prompt));
    };

    const handleSavePipeline = () => {
        // Save the pipeline using API
        axios.post('https://r3-pearl.ddns.net/api/prompt-pipelines', {
            name: pipelineName,
            prompts: pipelinePrompts.map(p => p.id),
        }).then(response => {
            alert('Pipeline saved successfully!');
            setPipelineName('');
            setPipelinePrompts([]);
        }).catch(error => console.error('Error saving pipeline:', error));
    };

    const handleUpdatePipeline = () => {
        // Update the pipeline logic based on the selected pipeline
        // Similar to save but using PUT and an existing pipeline ID.
    };

    const PipelineContent = React.memo(() => (
        <div>
            <h3>Create Prompt Pipeline</h3>
            <input
                type="text"
                placeholder="Pipeline Name"
                value={pipelineName}
                onChange={(e) => {
                    // Allow seamless input without interruption
                    setPipelineName(e.target.value);
                }}
                autoFocus // Automatically focus on this input when rendered
            />
            <h4>Select Prompts</h4>
            <select onChange={handleSelectPrompt} value={selectedPrompt?.id || ''}>
                <option value="">Select a prompt</option>
                {prompts.map(prompt => (
                    <option key={prompt.id} value={prompt.id}>
                        {prompt.prompt_name}
                    </option>
                ))}
            </select>
            <button onClick={handleAddToPipeline}>Add to Pipeline</button>
    
            <h4>Current Pipeline Prompts</h4>
            <ul>
                {pipelinePrompts.map(prompt => (
                    <li key={prompt.id}>
                        {prompt.prompt_name} <button onClick={() => handleRemoveFromPipeline(prompt)}>Remove</button>
                    </li>
                ))}
            </ul>
            <div>
                <button onClick={handleSavePipeline}>Save Prompt Pipeline</button>
                <button onClick={handleUpdatePipeline}>Update Prompt Pipeline</button>
            </div>
        </div>
    ));
    

    const handleFileUpload = async (files, directoryName) => {
        if (files.length === 0 || !directoryName) {
            alert('Please enter a directory name and select files to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('directory', directoryName);
        Array.from(files).forEach(file => formData.append('uploaded_files', file));

        // Create log data for XLSX file
        const logData = Array.from(files).map(file => ({
            filename: file.name,
            directory: directoryName
        }));

        // Create a new workbook and add the log data to it
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(logData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Upload Log');

        // Create a buffer for the XLSX file
        const logFileName = `${directoryName}_file_upload_log.xlsx`;
        XLSX.writeFile(workbook, logFileName);

        try {
            const response = await fetch('https://r3-pearl.ddns.net/api/upload-and-save', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('File upload error:', error);
        }
    };

    const handleUpdatePrompt = () => {
        if (!selectedPrompt) {
            alert('Please select a prompt to update.');
            return;
        }

        axios.put(`https://r3-pearl.ddns.net/api/prompts/update/${selectedPrompt.id}`, {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            const updatedPrompts = prompts.map(p =>
                p.id === response.data.id ? response.data : p
            );
            setPrompts(updatedPrompts);
        }).catch(error => console.error('Error updating prompt:', error));
    };

    const handleCreatePrompt = () => {
        axios.post('https://r3-pearl.ddns.net/api/prompts/create', {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            setPrompts([...prompts, response.data]);
            setNewPromptName('');
            setNewPromptText('');
        }).catch(error => console.error('Error creating new prompt:', error));
    };

    // const handleSaveSettings = () => {
    //     const updatedSettings = {
    //         ...settings,
    //         temperature: tempSliderValue,
    //         maxTokens: maxTokensSliderValue,
    //     };
    //     setSettings(updatedSettings);
    //     const username = localStorage.getItem('username'); // Ensure username is retrieved from local storage
    //     if (!username) {
    //         alert('Username is required to save settings.');
    //         return;
    //     }
    //     const payload = {
    //         username: username,
    //         settings: updatedSettings
    //     };
    //     axios.post('/api/save-settings', payload)
    //         .then(response => {
    //             alert('Settings saved successfully!');
    //         })
    //         .catch(error => {
    //             console.error('Error saving settings:', error);
    //             alert('Failed to save settings.');
    //         });
    // };

    const handleSaveSettings = () => {
        const updatedSettings = {
            ...settings,
            temperature: tempSliderValue,
            maxTokens: maxTokensSliderValue,
        };
        setSettings(updatedSettings);
        const username = localStorage.getItem('username'); // Ensure username is retrieved from local storage
        if (!username) {
            alert('Username is required to save settings.');
            return;
        }
        const payload = {
            username: username,
            settings: updatedSettings
        };
        axios.post('/api/save-settings', payload)
            .then(response => {
                alert('Settings saved successfully!');
            })
            .catch(error => {
                console.error('Error saving settings:', error);
                alert('Failed to save settings.');
            });
    };

    // const handleSelectPipeline = (event) => {
    //     const pipelineName = event.target.value;
    //     setSelectedPipeline(pipelineName);
    //     if (pipelineName) {
    //         axios.get(`https://r3-pearl.ddns.net/api/prompt-pipelines/${pipelineName}`)
    //             .then(response => {
    //                 if (response.data && Array.isArray(response.data)) {
    //                     const promptIds = response.data; // Assumes response is an array of IDs
    //                     // Map the promptIds to the actual prompt objects using previously fetched prompts
    //                     const orderedPrompts = promptIds.map(id => prompts.find(p => p.id === id)).filter(p => p !== undefined); // Filter out undefined
    
    //                     setPipelinePrompts(orderedPrompts); // Update state with ordered prompts for display
    //                 } else {
    //                     console.error('Error fetching prompts: Received unexpected response structure', response.data);
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching pipeline:', error);
    //                 alert('Could not fetch pipeline. Please try again later.');
    //             });
    //     }
    // };

    // const handleSelectPipeline = (event) => {
    //     const pipelineName = event.target.value;
    //     setSelectedPipeline(pipelineName);
    //     if (pipelineName) {
    //         axios.get(`https://r3-pearl.ddns.net/api/prompt-pipelines/${pipelineName}`)
    //             .then(response => {
    //                 if (Array.isArray(response.data)) { // Confirming data is indeed an array of IDs
    //                     const promptIds = response.data;
                        
    //                     // Map the prompt IDs to their details
    //                     const orderedPrompts = promptIds.map(id => prompts.find(p => p.id === id)).filter(p => p !== undefined); // Fetch and filter
                        
    //                     // Update the pipeline prompts with the newly ordered prompts
    //                     if (orderedPrompts.length > 0) {
    //                         setPipelinePrompts(orderedPrompts);
    //                     } else {
    //                         console.error('No valid prompts found for the selected pipeline.');
    //                         setPipelinePrompts([]); // Reset if nothing valid
    //                     }
    //                 } else {
    //                     console.error('Received unexpected response structure: Expected an array of IDs', response.data);
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching pipeline:', error);
    //                 alert('Could not fetch pipeline. Please try again later.');
    //             });
    //     }
    // };

    const handleSelectPipeline = (event) => {
        const pipelineName = event.target.value;
        setSelectedPipeline(pipelineName);
        
        if (pipelineName) {
            axios.get(`https://r3-pearl.ddns.net/api/prompt-pipelines/${pipelineName}`)
                .then(response => {
                    if (typeof response.data === 'string') {
                        // Convert the string of prompt IDs into an array of numbers
                        const promptIds = response.data
                            .slice(1, -1) // Remove the brackets
                            .split(',')
                            .map(id => parseInt(id.trim(), 10)); // Convert to integers
    
                        // Retrieve the prompt names based on the IDs received from the response
                        const orderedPrompts = promptIds.map(id => prompts.find(p => p.id === id)).filter(p => p !== undefined);
    
                        // Update the pipeline prompts
                        if (orderedPrompts.length > 0) {
                            setPipelinePrompts(orderedPrompts);
                        } else {
                            console.error('No valid prompts found for the selected pipeline.');
                            setPipelinePrompts([]);
                        }
                    } else {
                        console.error('Received unexpected response structure: Expected a string of IDs', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching pipeline:', error);
                    alert('Could not fetch pipeline. Please try again later.');
                });
        }
    };
    
    

    const MainContent = ({ settings, providerModelMap }) => {
        const currentProviderModels = providerModelMap[settings.llmProvider];
        const currentModel = currentProviderModels.find(model => model.name === settings.model);

        return (
            <div>
                <h3>Main Settings</h3>
                <p>Configure your main settings here.</p>
                <div>
                    <label>
                        Select LLM Provider:
                        <select value={settings.llmProvider} onChange={(e) => handleSettingChange('llmProvider', e.target.value)}>
                            {Object.keys(providerModelMap).map(provider => (
                                <option key={provider} value={provider}>{provider}</option>
                            ))};
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Select Model:
                        <select value={settings.model} onChange={(e) => handleSettingChange('model', e.target.value)}>
                            {
                                providerModelMap[settings.llmProvider]?.map(model => (
                                    <option key={model.name} value={model.name}>{model.name}</option>
                                )) || <option value="">No Models Available</option>
                            }
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Temperature:
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.05"
                            value={tempSliderValue}
                            onChange={(e) => setTempSliderValue(parseFloat(e.target.value))}
                        />
                        <span>{tempSliderValue}</span>
                    </label>
                </div>
                <div>
                    <label>
                        Max Tokens: ({maxTokenValue})
                        <input
                            type="range"
                            min="0"
                            max={maxTokenValue} // Use maxTokenValue instead of maxTokens
                            step="1"
                            value={maxTokensSliderValue}
                            onChange={(e) => setMaxTokensSliderValue(parseInt(e.target.value))}
                        />
                        <span>{maxTokensSliderValue}</span>
                    </label>
                </div>
                <button onClick={handleSaveSettings}>Save Settings</button>
            </div>
        );
    };

    return (
        <div>
            <Header />
            <div className="settings-page-container">
                <SettingsSidebar
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleSelectPipeline={handleSelectPipeline}
                    pipelines={pipelines}
                    selectedPipeline={selectedPipeline}
                />
                <div className="content">
                    <h2>Settings</h2>
                    <div className="tab-content">
                        {activeTab === 'Main' && (
                            <MainContent
                                settings={settings}
                                providerModelMap={providerModelMap}
                            />
                        )}
                        {activeTab === 'Prompts' && (
                            <div>
                                <h3>Select a System Prompt</h3>
                                <select onChange={handleSelectPrompt} value={selectedPrompt?.id || ''}>
                                    <option value="">Select a prompt</option>
                                    {prompts.map(prompt => (
                                        <option key={prompt.id} value={prompt.id}>
                                            {prompt.prompt_name}
                                        </option>
                                    ))}
                                </select>

                                <div style={{ marginTop: '20px' }} className="prompt-details">
                                    <h3>Prompt Details</h3>
                                    <label>
                                        Name:
                                        <input
                                            type="text"
                                            value={newPromptName}
                                            onChange={(e) => setNewPromptName(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        Text:
                                        <textarea
                                            value={newPromptText}
                                            onChange={(e) => setNewPromptText(e.target.value)}
                                        />
                                    </label>
                                    <div className="prompt-buttons">
                                        <button onClick={handleUpdatePrompt}>Update Prompt</button>
                                        <button onClick={handleCreatePrompt}>Save as New Prompt</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'Prompt Pipelines' && <PipelineContent />}
                        {selectedPipeline && <div>You have selected: {selectedPipeline}</div>}
                        {activeTab === 'Data Upload' && (
                            <FileUploadHandler />
                        )}
                        {activeTab === 'Other Settings' && <OtherSettingsContent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const OtherSettingsContent = () => (
    <div>
        <h3>Other Settings</h3>
        <p>Configure other application settings here.</p>
    </div>
);

const handleFileUpload = async (files, directoryName) => {
    if (files.length === 0 || !directoryName) {
        alert('Please enter a directory name and select files to upload.');
        return;
    }

    const formData = new FormData();
    formData.append('directory', directoryName);
    Array.from(files).forEach(file => formData.append('uploaded_files', file));

    try {
        const response = await fetch('https://r3-pearl.ddns.net/api/upload-and-save', {
            method: 'POST',
            body: formData,
        });
        const result = await response.json();
        console.log(result);
    } catch (error) {
        console.error('File upload error:', error);
    }
};

const FileUploadHandler = () => {
    const [files, setFiles] = useState([]);
    const [directoryName, setDirectoryName] = useState('');

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleDirectoryChange = (event) => {
        setDirectoryName(event.target.value);
    };

    return (
        <div>
            <h3>Upload Files</h3>
            <input
                type="text"
                placeholder="Enter directory name"
                value={directoryName}
                onChange={handleDirectoryChange}
            />
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={() => handleFileUpload(files, directoryName)}>Upload</button>
        </div>
    );
};

export default SettingsPage;