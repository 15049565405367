import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import '../styles.css';
import ChatSettingsModal from './ChatSettingsModal';
import './ChatWindow.css';

const ChatWindow = ({ selectedChat, setSelectedChat }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);
    const [edited, setEdited] = useState(false);
    const [useRAG, setUseRAG] = useState(false);
    const [useKNOWLEDGE, setUseKNOWLEDGE] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [useText2SQL, setUseText2SQL] = useState(false);
    const [useAgentAPI, setUseAgentAPI] = useState(false);
    const [pipelines, setPipelines] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState('');
    const [dbConfig, setDbConfig] = useState({
        db_name: '',
        db_user: '',
        db_password: '',
        db_host: ''
    });
    const [isChatSettingsOpen, setIsChatSettingsOpen] = useState(false);
    const [systemPrompt, setSystemPrompt] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [loopsBeforeHumanInput, setLoopsBeforeHumanInput] = useState(1);
    const [seeThoughtsAndActions, setSeeThoughtsAndActions] = useState(false);

    const [filesData, setFilesData] = useState([]); // Define filesData state
    const [activeCollectionFiles, setActiveCollectionFiles] = useState([]); // Define activeCollectionFiles state

    const baseURL = 'https://r3-pearl.ddns.net'; // Define baseURL
    const knGraphOption = 'kn_graph_full'; // Replace 'yourGraphOptionKey' with the actual key
    const selectedSystemPrompt = ''; // Initialize or replace as needed

        // New state for the Tools checkboxes
    const [selectedTools, setSelectedTools] = useState({
        saveTextToFile: false,
        listFilesInDirectory: false,
        relevantText: true,
        googlesearch: false,
        queryDatabases: false,
        getCollectionIds: true,
        searchCollectionKnowledge: true,
        readFileContent: false,
        collectionKnowledge: true
    });
    
    

    const [settings, setSettings] = useState(() => {
        const savedSettings = localStorage.getItem('llmSettings');
        return savedSettings ? JSON.parse(savedSettings) : {};
    });

    const FIVE_MINUTES_TIMEOUT = 300000; // 5 minutes

    useEffect(() => {
        if (selectedChat) {
            try {
                const loadedMessages = JSON.parse(selectedChat.chat_history);
                setMessages(loadedMessages);
                setInitialLoad(false);
                setEdited(false);
            } catch (err) {
                console.error('Error parsing chat history:', err);
                setMessages([]);
            }
        }
    }, [selectedChat]);

    const formatMarkdownTable = (tableMarkdown) => {
        return tableMarkdown.split("\n").map(line => line.trim()).join("\n");
    };

    // const attemptRequest = async (messages, provider, retry = 0) => {
    //     try {
    //         setLoading(true);
    //         const response = await axios.post('https://r3-pearl.ddns.net/api/agent-messages', {
    //             messages,
    //             human_loop: loopsBeforeHumanInput,
    //             provider // Pass the provider to the request
    //         }, {
    //             timeout: FIVE_MINUTES_TIMEOUT
    //         });
    //         return response.data;
    //     } catch (error) {
    //         if (error.code === 'ECONNABORTED' && retry < 1) {
    //             console.log('Request timeout, retrying...');
    //             return await attemptRequest(messages, provider, retry + 1);
    //         } else {
    //             console.error('Error fetching data:', error);
    //             setError('Failed to receive response. Please try again later.');
    //             throw error;
    //         }
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const attemptRequest = async (messages, provider, selectedTools, retry = 0) => {
        console.log('Selected Tools:', selectedTools)
        try {
            setLoading(true);
            const response = await axios.post('https://r3-pearl.ddns.net/api/agent-messages', {
                messages,
                human_loop: loopsBeforeHumanInput,
                provider,
                selectedTools // Pass the selectedTools to the request
            }, {
                timeout: FIVE_MINUTES_TIMEOUT
            });
            return response.data;
        } catch (error) {
            if (error.code === 'ECONNABORTED' && retry < 1) {
                console.log('Request timeout, retrying...');
                return await attemptRequest(messages, provider, selectedTools, retry + 1);
            } else {
                console.error('Error fetching data:', error);
                setError('Failed to receive response. Please try again later.');
                throw error;
            }
        } finally {
            // setLoading(false);
            console.log('Agent run complete onto summary')
        }
    };

    // const fetchPipelinePrompts = async (pipelineName) => {
    //     const response = await axios.get(`/api/prompt-pipelines/${pipelineName}`);
    //     return JSON.parse(response.data.pipeline);
    //   };
    
    const fetchPipelinePrompts = async (pipelineName) => {
        try {
          const response = await axios.get(`/api/prompt-pipelines/${pipelineName}`);
          // Check if response data is a valid JSON string
          if (response.data && typeof response.data === 'string') {
            const parsedData = JSON.parse(response.data); // Attempt to parse
            console.log('Parsed pipeline prompts:', parsedData);
            return parsedData;
          } else {
            console.error('Pipeline data is not in expected string format:', response.data);
            throw new Error('Invalid pipeline data format');
          }
        } catch (error) {
          console.error('Error fetching pipeline prompts:', error);
          throw error; // Re-throw after logging
        }
      };

    const fetchAllPrompts = async () => {
        const response = await axios.get('/api/prompts');
        return response.data;
      };

    const handleSend = async () => {
        if (!input.trim()) return;
    
        setEdited(true);
        const userMessage = { role: 'user', content: input };
        const systemMessageBase = {
            role: 'system',
            content: systemPrompt || 'You are an Intelligent Chatbot developed by Rock River Research who analyzes the user request to perform the required steps to provide a thorough response.'
        };
    
        const newMessages = [...messages, systemMessageBase, userMessage];
        // setMessages(newMessages);
        setInput('');
        setError('');
    
        try {
            setLoading(true);
            const token = localStorage.getItem('token'); // Retrieve JWT token
            console.log('selectedPipeline:', selectedPipeline)
    
            // if (useAgentAPI && selectedPipeline) {
            //     try {
            //         // Fetch pipeline prompts
            //         const pipelinePrompts = await fetchPipelinePrompts(selectedPipeline);
            //         // Fetch all prompts
            //         const allPrompts = await fetchAllPrompts();
            //         console.log('Retrieved Prompts', allPrompts)
        
            //         // Create a list of systemPrompts based on the pipeline IDs
            //         const pipelineSystemPrompts = allPrompts
            //             .filter(prompt => pipelinePrompts.includes(prompt.id))
            //             .map(prompt => prompt.prompt_text);

            //         console.log('Pipeline System Prompts',pipelineSystemPrompts)
        
            //         for (const pipelineSystemPrompt of pipelineSystemPrompts ) {
            //             // Iterate and send system prompts
            //             // const contextualSystemMessage = {
            //             //     ...systemMessageBase,
            //             //     content: systemPrompt,
            //             // };

            //             console.log('Selected Prompt',pipelineSystemPrompt)

            //             const contextualSystemMessage = {
            //                 role: 'system',
            //                 content: pipelineSystemPrompt
            //             };
            //             const iterativeMessages = [...messages, contextualSystemMessage, userMessage];

            //             setMessages(iterativeMessages);
        
            //             const data = await attemptRequest(iterativeMessages, settings.llmProvider, selectedTools);
            //             if (data.output && Array.isArray(data.output)) {
            //                 const updatedMessages = data.output.map(item => ({
            //                     role: item.role,
            //                     content: item.content,
            //                 }));
        
            //                 const agentNewMessages = [...iterativeMessages, ...updatedMessages];
            //                 const agentResponse = await axios.post(`${baseURL}/api/prochat`, {
            //                     messages: agentNewMessages
            //                 }, {
            //                     headers: {
            //                         'Authorization': `Bearer ${token}`
            //                     },
            //                     timeout: 300000
            //                 });
        
            //                 let agentAssistantMessage = { role: 'assistant', content: agentResponse.data.choices[0].message.content };
            //                 setMessages([...iterativeMessages, agentAssistantMessage]);
        
            //                 if (!agentAssistantMessage.content.includes('NEXT AGENT MESSAGE')) {
            //                     break;
            //                 }
            //             } else {
            //                 throw new Error('Invalid format from server response.');
            //             }
            //         }
            //     } catch (error) {
            //         console.error('Error in pipeline processing:', error);
            //         setError('Failed to process pipeline. Please try again later.');
            //     }
            // } 
            if (useAgentAPI && selectedPipeline) {
                try {
                    const pipelinePrompts = await fetchPipelinePrompts(selectedPipeline);
                    const allPrompts = await fetchAllPrompts();
    
                    const pipelineSystemPrompts = allPrompts
                        .filter(prompt => pipelinePrompts.includes(prompt.id))
                        .map(prompt => prompt.prompt_text);
    
                    for (const pipelineSystemPrompt of pipelineSystemPrompts) {
                        // Iterate and send prompts where each step is based on previous messages
                        const contextualSystemMessage = {
                            role: 'system',
                            content: pipelineSystemPrompt
                        };
                        const iterativeMessages = [...messages, contextualSystemMessage, userMessage];
    
                        // Preserve previous messages in the session
                        const data = await attemptRequest(iterativeMessages, settings.llmProvider, selectedTools);
    
                        if (data.output && Array.isArray(data.output)) {
                            const updatedMessages = data.output.map(item => ({
                                role: item.role,
                                content: item.content
                            }));
    
                            // Update messages state after fetching new data
                            const agentNewMessages = [...iterativeMessages, ...updatedMessages];
                            const agentResponse = await axios.post(`${baseURL}/api/prochat`, {
                                messages: agentNewMessages
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                },
                                timeout: 300000
                            });
    
                            let agentAssistantMessage = { role: 'assistant', content: agentResponse.data.choices[0].message.content };
                            setMessages([...iterativeMessages, agentAssistantMessage]);
    
                            // Exit if the condition determines no further action
                            if (!agentAssistantMessage.content.includes('NEXT AGENT MESSAGE')) {
                                break;
                            }
                        } else {
                            throw new Error('Error: Invalid format from server response.');
                        }
                    }
                } catch (error) {
                    console.error('Error in pipeline processing:', error);
                    setError('Pipeline processing failed. Please try again.');
                } finally {
                    // setLoading(false);
                    console.log('Agent run complete onto summary')
                }
            } else if (useAgentAPI) {
                setMessages(newMessages);
                console.log('Standard Agent Process')
                console.log(newMessages);
                // const data = await attemptRequest(newMessages, settings.llmProvider); // Pass provider to attemptRequest
                // const data = await attemptRequest(newMessages, settings.llmProvider, selectedTools); // Pass selectedTools here

                const TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

                const attemptRequestWithTimeout = (newMessages, llmProvider, selectedTools) => {
                    return new Promise((resolve, reject) => {
                        const timeoutId = setTimeout(() => {
                            reject(new Error("Request timed out after 5 minutes"));
                        }, TIMEOUT_DURATION);
                        
                        attemptRequest(newMessages, llmProvider, selectedTools)
                            .then((result) => {
                                clearTimeout(timeoutId); // Clear the timeout if request is successful
                                resolve(result);
                            })
                            .catch((error) => {
                                clearTimeout(timeoutId); // Clear the timeout on failure
                                reject(error);
                            });
                    });
                };

                try {
                    const data = await attemptRequestWithTimeout(newMessages, settings.llmProvider, selectedTools);              

                    if (data.output && Array.isArray(data.output)) {
                        const updatedMessages = data.output.map(item => ({
                            role: item.role,
                            content: item.content
                        }));

                        console.log(updatedMessages)

                        const agentNewMessages = [...newMessages, ...updatedMessages];

                        const agentResponse = await axios.post(`${baseURL}/api/prochat`, {
                            messages: agentNewMessages
                        }, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            },
                            timeout: 300000
                        });

                        const provider = settings.llmProvider || 'OpenAI';

                        let agentAssistantMessage;
                        if (provider === 'OpenAI') {
                            agentAssistantMessage = { role: 'assistant', content: agentResponse.data.choices[0].message.content };
                        } else {
                            agentAssistantMessage = { role: 'assistant', content: agentResponse.data };
                        }

                        if (seeThoughtsAndActions) {
                            setMessages([...newMessages, ...updatedMessages, agentAssistantMessage]);
                        } else {
                            setMessages([...newMessages, agentAssistantMessage]);
                        }
                    } else {
                        throw new Error('Invalid format from server response.');
                    }
                } catch (error) {
                    console.error(error.message);
                }
            } else if (useText2SQL) {
                setMessages(newMessages);
                if (!dbConfig.db_name || !dbConfig.db_user || !dbConfig.db_password || !dbConfig.db_host) {
                    setError('Database configuration is missing.');
                    console.error('Database configuration is incomplete:', dbConfig);
                    return;
                }
                const response = await axios.post('https://r3-pearl.ddns.net/api/text2sql', { messages: newMessages, dbConfig });
                const resultContent = formatMarkdownTable(response.data.result);

                const text2sqlMessageContent = `
                    **Query Executed**:

                    \`\`\`sql
                    ${response.data.query}
                    \`\`\`

                    **Results**:
                    ${resultContent}
                `;

                const text2sqlAssistantMessage = {
                    role: 'assistant',
                    content: text2sqlMessageContent
                };

                setMessages([...newMessages, text2sqlAssistantMessage]);
            } else if (useRAG) {
                setMessages(newMessages);
                const endpoint = 'https://r3-pearl.ddns.net/api/rag-chat';

                const payload = {
                    userInput: input,
                    systemPrompt: systemPrompt || 'You are an Intelligent Chatbot developed by Rock River Research. Respond to the user query with your in-depth knowledge. Provide a VERBOSE and STEP-BY-STEP response to their query. Use any provided context or chat history to inform your response.',
                    messages: [...messages]
                };

                const response = await axios.post(endpoint, payload, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    timeout: FIVE_MINUTES_TIMEOUT
                });

                const assistantMessage = { role: 'assistant', content: response.data.content };

                setMessages([...newMessages, assistantMessage]);
            } else if (useKNOWLEDGE) {
                setMessages(newMessages);
                try {
                    const response = await axios.post(`${baseURL}/api/useKnowledge`, {
                        selectedName,
                        messages: newMessages,
                        systemPrompt: systemPrompt || 'You are an Intelligent Chatbot developed by Rock River Research...',
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                        timeout: 300000 // Set timeout to 5 minutes
                    });
            
                    // Log response to check its structure
                    console.log('Response from /api/useKnowledge:', response);

                    // Log response to check its structure
                    console.log('Response data from /api/useKnowledge:', response.data);

                    // Ensure response.data.messages exists
                    if (response.data.content) {
                        const assistantMessage = {
                            role: 'assistant',
                            content: response.data.content || "No content found."
                        };
                        setMessages([...newMessages, assistantMessage]); // Update the state with new messages
                    } else {
                        throw new Error('Invalid response format received.');
                    }
            
                } catch (error) {
                    console.error('Error during knowledge processing:', error);
                    setError('Failed to process knowledge. Please try again later.');
                }
            }    
            
            else {
                setMessages(newMessages);
                // Use the new '/api/prochat' endpoint
                const endpoint = 'https://r3-pearl.ddns.net/api/prochat';

                const payload = {
                    messages: newMessages
                };

                const response = await axios.post(endpoint, payload, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    timeout: FIVE_MINUTES_TIMEOUT
                });

                // Get provider from settings to handle response formatting
                const provider = settings.llmProvider || 'OpenAI';

                let assistantMessage;
                if (provider === 'OpenAI') {
                    assistantMessage = { role: 'assistant', content: response.data.choices[0].message.content };
                } else if (provider === 'OpenSource') {
                    assistantMessage = { role: 'assistant', content: response.data };
                } else {
                    assistantMessage = { role: 'assistant', content: response.data };
                }

                setMessages([...newMessages, assistantMessage]);
            }
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send message. Please try again after some time.');
        } finally {
            setLoading(false);
        }
    };

    const handleClear = async () => {
        const isNewOrUnchanged = initialLoad || (selectedChat && selectedChat.chat_history === JSON.stringify(messages));

        if (!edited && isNewOrUnchanged) {
            setMessages([]);
            setSelectedChat && setSelectedChat(null); // Ensure setSelectedChat is called only if it is a function
            return;
        }

        const promptName = selectedChat?.prompt_name || 'Default Prompt';
        const username = localStorage.getItem('username');
        const token = localStorage.getItem('token'); // Retrieve JWT token


        try {
            const response = await axios.post('https://r3-pearl.ddns.net/api/clear', {
                messages,
                promptName,
                username
            }, {
                headers: { 'Authorization': `Bearer ${token}` } // Include the token in the request
            });

            if (response.status === 200) {
                setMessages([]);
                setSelectedChat && setSelectedChat(null); // Ensure setSelectedChat is called only if it is a function
                setEdited(false);
            } else {
                setError('Failed to clear chat on the server. Please try again.');
            }
        } catch (err) {
            setError('Failed to clear chat. Please try again.');
        }
    };

    return (
        <div
            className="chat-window"
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                boxSizing: 'border-box',
                paddingTop: '60px'
            }}
        >
            <div
                className="chat-area"
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '10px'
                }}
            >
                {error && <div className="error-message">{error}</div>}
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={msg.role === 'user' ? 'user' : 'assistant'}>
                            <strong>{msg.role === 'user' ? 'User' : 'Assistant'}:</strong>
                            <Markdown>{msg.content}</Markdown>
                        </div>
                    ))}
                    {loading && <div>Loading...</div>}
                </div>
                <div
                    className="chat-input-bar"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      borderTop: '1px solid #ccc',
                      backgroundColor: 'white',
                      position: 'sticky',
                      bottom: 0
                    }}
                >
                    <input
                        type="text"
                        style={{ flex: 1, marginRight: '10px' }}
                        value={input}
                        placeholder="Type your message..."
                        onChange={e => setInput(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSend();
                            }
                        }}
                    />
                    <button onClick={handleSend}>Send</button>
                    <button onClick={handleClear} style={{ marginLeft: '5px' }}>Clear</button>
                    <button onClick={() => setIsChatSettingsOpen(true)} style={{ marginLeft: '5px' }}>Chat Settings</button>
                </div>
            </div>
            <ChatSettingsModal
                isOpen={isChatSettingsOpen}
                onClose={(loops, tools, pipeline) => {
                    setIsChatSettingsOpen(false);
                    setLoopsBeforeHumanInput(loops);
                    setSelectedTools(tools); // store the selected tools in state
                    setSelectedPipeline(pipeline);
                }}
                useRAG={useRAG}
                setUseRAG={setUseRAG}
                setUseKNOWLEDGE={setUseKNOWLEDGE}
                useText2SQL={useText2SQL}
                setUseText2SQL={setUseText2SQL}
                useAgentAPI={useAgentAPI}
                setUseAgentAPI={setUseAgentAPI}
                setDbConfig={setDbConfig}
                onSelectPrompt={(promptText) => {
                    setSystemPrompt(promptText);
                }}
                loopsBeforeHumanInput={loopsBeforeHumanInput}
                setLoopsBeforeHumanInput={setLoopsBeforeHumanInput}
                seeThoughtsAndActions={seeThoughtsAndActions}
                setSeeThoughtsAndActions={setSeeThoughtsAndActions}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                selectedTools={selectedTools} // Pass selectedTools to the ChatSettingsModal if needed
                selectedPipeline = {selectedPipeline}
            />
        </div>
    );
};

export default ChatWindow;