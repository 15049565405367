// import React, { useState, useEffect } from 'react';

// const SettingsSidebar = ({ activeTab, setActiveTab, handleSelectPipeline, setSelectedPipeline, selectedPipeline, pipelines = []  }) => {
    // const [pipelines, setPipelines] = useState([]);

    // useEffect(() => {
    //     const fetchPipelines = async () => { // Fetch pipelines on mount
    //         const response = await fetch('/api/pipelines');
    //         const data = await response.json();
    //         setPipelines(data);
    //     };
    //     fetchPipelines();
    // }, []);

//     return (
//         <div className="sidebar">
//             {['Main', 'Prompts', 'Prompt Pipelines', 'Data Upload', 'Other Settings'].map((tab) => (
//                 <button
//                     key={tab}
//                     className={`sidebar-button ${activeTab === tab ? 'active' : ''}`}
//                     onClick={() => setActiveTab(tab)}
//                 >
//                     {tab}
//                 </button>
//             ))}
//             {activeTab === 'Prompt Pipelines' && (
//                 <div>
//                     <h3>Select Prompt Pipeline</h3>
//                     <select onChange={handleSelectPipeline}>
//                         <option value="">Select a pipeline</option>
//                         {pipelines.map((pipeline) => (
//                             <option key={pipeline} value={pipeline}>
//                                 {pipeline}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default SettingsSidebar;

// import React, { useState, useEffect } from 'react';

// const SettingsSidebar = ({ 
//     activeTab, 
//     setActiveTab, 
//     handleSelectPipeline, 
//     setSelectedPipeline,       // Ensure to receive this prop
//     selectedPipeline           // Ensure to receive this prop
// }) => {

//     const [pipelines, setPipelines] = useState([]);

//     useEffect(() => {
//         const fetchPipelines = async () => { // Fetch pipelines on mount
//             const response = await fetch('/api/prompt-pipelines');
//             const data = await response.json();
//             setPipelines(data);
//         };
//         fetchPipelines();
//     }, []);

//     return (
//         <div className="sidebar">
//             {['Main', 'Prompts', 'Prompt Pipelines', 'Data Upload', 'Other Settings'].map((tab) => (
//                 <button
//                     key={tab}
//                     className={`sidebar-button ${activeTab === tab ? 'active' : ''}`}
//                     onClick={() => setActiveTab(tab)}
//                 >
//                     {tab}
//                 </button>
//             ))}
//             {activeTab === 'Prompt Pipelines' && (
//                 <div>
//                     <h3>Select Prompt Pipeline</h3>
//                     <select onChange={handleSelectPipeline} value={selectedPipeline || ''}>
//                         <option value="">Select a pipeline</option>
//                         {pipelines.map(pipeline => (
//                             <option key={pipeline.id} value={pipeline.name}>
//                                 {pipeline.name}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default SettingsSidebar;

import React, { useState, useEffect } from 'react';

const SettingsSidebar = ({ activeTab, setActiveTab, handleSelectPipeline, selectedPipeline }) => {
    const [pipelines, setPipelines] = useState([]);

    useEffect(() => {
        const fetchPipelines = async () => {
            const response = await fetch('/api/prompt-pipelines');

            console.log('fetched pipelines:', response)
            const data = await response.json();
            setPipelines(data);
        };
        fetchPipelines();
    }, []);

    return (
        <div className="sidebar">
            {['Main', 'Prompts', 'Prompt Pipelines', 'Data Upload', 'Other Settings'].map((tab) => (
                <button
                    key={tab}
                    className={`sidebar-button ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
            ))}
            {activeTab === 'Prompt Pipelines' && (
                <div>
                    <h3>Select Prompt Pipeline</h3>
                    <select onChange={handleSelectPipeline} value={selectedPipeline || ''}>
                        <option value="">Select a pipeline</option>
                        {pipelines.map(pipeline => (
                            <option key={pipeline.id} value={pipeline.name}>
                                {pipeline.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default SettingsSidebar;


