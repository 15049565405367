import React, { useState } from 'react';
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://r3-pearl.ddns.net/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();

            if (data.success) {
                localStorage.setItem('isLoggedIn', 'true');

                // Store JWT token
                localStorage.setItem('token', data.token);
                localStorage.setItem('token', data.token);
                localStorage.setItem('username', username); // Store the username
                window.location.reload();

                // Store username for future reference
                localStorage.setItem('username', username);

                window.location.reload();
            } else {
                alert(data.message || 'Login failed!');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('Error during login. Please try again later.');
        }
    };

    return (
        <div className="home-container">
            <div className="text-panel">
                <h1>Login to Pearl</h1>
                <form onSubmit={handleLogin}>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} className="login-input" required />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="login-input" required />
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
            <div className="image-panel">
                <img src="/pearl_logo.jpg" alt="Pearl Logo" className="home-logo" />
                <p className="companion-text">Your Productivity and Data Companion</p>
            </div>
        </div>
    );
}

export default Login;