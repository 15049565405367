// Updated ChatSettingsModal.js
// import { useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatSettingsModal.css'; // Ensure the CSS path is correct


const ChatSettingsModal = ({
  isOpen,
  onClose,
  useRAG,
  setUseRAG,
  useKNOWLEDGE,
  setUseKNOWLEDGE,
  setSelectedIds,
  setUseText2SQL,
  useText2SQL,
  setDbConfig,
  useAgentAPI,
  setUseAgentAPI,
  onSelectPrompt = () => {},
  seeThoughtsAndActions,
  setSeeThoughtsAndActions,
  selectedName, // Add selectedName as a prop
  setSelectedName // Add setSelectedName as a prop
}) => {
  const [names, setNames] = useState([]);
  const [selectedIds, setInternalSelectedIds] = useState([]);
  const [activeTab, setActiveTab] = useState('Retrieval');
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [loopsBeforeHumanInput, setLoopsBeforeHumanInput] = useState(1);

  // New state for the Tools checkboxes
  const [selectedTools, setSelectedTools] = useState({
    saveTextToFile: false,
    listFilesInDirectory: false,
    relevantText: true,
    googlesearch: false,
    queryDatabases: false,
    searchCollectionKnowledge: true,
    readFileContent: false,
    collectionKnowledge: true
  });

    // New state for the Agent Pipelines
    const [pipelines, setPipelines] = useState([]);
    const [selectedPipeline, setSelectedPipeline] = useState('');

  const baseURL = 'https://r3-pearl.ddns.net';
  const DISPLAY_LIMIT = 10;
  

  useEffect(() => {
    async function fetchNames() {
      try {
        const response = await axios.get(`${baseURL}/api/names`);
        setNames(response.data);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    }
    fetchNames();
  }, [baseURL]);

  useEffect(() => {
    async function fetchDatabases() {
      try {
        const response = await axios.get(`${baseURL}/api/remote_bds`);
        setDatabases(response.data);
      } catch (error) {
        console.error('Error fetching databases:', error);
      }
    }
    fetchDatabases();
  }, [baseURL]);

  useEffect(() => {
    async function fetchPrompts() {
      try {
        const response = await axios.get(`${baseURL}/api/prompts`);
        setPrompts(response.data);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    }
    fetchPrompts();
  }, [baseURL]);

  useEffect(() => {
    async function fetchFileIds() {
      if (selectedName) {
        try {
          const response = await axios.get(`${baseURL}/api/files/${selectedName}`);
          setInternalSelectedIds(response.data);
          setSelectedIds(response.data);
        } catch (error) {
          console.error('Error fetching file IDs:', error);
        }
      }
    }
    fetchFileIds();
  }, [selectedName, baseURL, setSelectedIds]);

  useEffect(() => {
    const dbDetails = databases.find(db => db.db_name === selectedDatabase);
    if (dbDetails) {
      console.log('Updating dbConfig with:', dbDetails);
      setDbConfig(dbDetails);
    }
  }, [selectedDatabase, databases, setDbConfig]);

  useEffect(() => {
    console.log('Current loopsBeforeHumanInput:', loopsBeforeHumanInput);
  }, [loopsBeforeHumanInput]);

  useEffect(() => {
    async function fetchPipelines() {
      const response = await axios.get(`${baseURL}/api/prompt-pipelines`);
      setPipelines(response.data);
    }
    fetchPipelines();
  }, [baseURL]);

  if (!isOpen) return null;

  const handleSelect = (event) => {
    const promptId = event.target.value;
    const prompt = prompts.find(p => p.id.toString() === promptId);
    setSelectedPrompt(prompt || null);
    setShowFullText(false);
    if (prompt) {
      onSelectPrompt(prompt.prompt_text);
    }
    console.log('Selected Prompt:', prompt);
  };

  // const handleSave = () => {
  //   console.log('Loops before Human Input (on save):', loopsBeforeHumanInput);
  //   onClose(loopsBeforeHumanInput);
  // };

  const handleSave = () => {
    console.log('Selected Tools before close:', selectedTools);
    // onClose(loopsBeforeHumanInput, selectedTools); // Pass selectedTools to onClose
    onClose(loopsBeforeHumanInput, selectedTools, selectedPipeline); 
    // onClose(1, {}, selectedPipeline);
  };

  // Handle the change of tool selection
  const handleToolChange = (tool) => {
    setSelectedTools((prevSelectedTools) => ({
      ...prevSelectedTools,
      [tool]: !prevSelectedTools[tool],
    }));
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Chat Settings</h2>

        <div className="tabs">
          {['Retrieval', 'Text2SQL', 'Agents', 'Prompts', 'Tools'].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="tab-content">
          {activeTab === 'Retrieval' && (
            <div>
              <h3><strong>Retrieval Settings</strong></h3>
              <div className="setting-grid">
                <div className="setting-item">
                  <span>Use RAG</span>
                  <input
                    type="checkbox"
                    checked={useRAG}
                    onChange={e => setUseRAG(e.target.checked)}
                  />
                </div>
                <div className="setting-item">
                  <span>Use Knowledge</span>
                  <input
                    type="checkbox"
                    checked={useKNOWLEDGE}
                    onChange={e => setUseKNOWLEDGE(e.target.checked)}
                  />
                </div>
                <div className="setting-item">
                  <span>Select a Name:</span>
                  <select value={selectedName} onChange={(e) => setSelectedName(e.target.value)}>
                    <option value="">--Select a Name--</option>
                    {names.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'Text2SQL' && (
            <div>
              <h3><strong>Text2SQL Settings</strong></h3>
              <div className="setting-grid">
                <div className="setting-item">
                  <span>Use Text2SQL</span>
                  <input
                    type="checkbox"
                    checked={useText2SQL}
                    onChange={e => setUseText2SQL(e.target.checked)}
                  />
                </div>
                <div className="setting-item">
                  <span>Select a Database:</span>
                  <select value={selectedDatabase} onChange={(e) => setSelectedDatabase(e.target.value)}>
                    <option value="">--Select a Database--</option>
                    {databases.map((db) => (
                      <option key={db.db_name} value={db.db_name}>
                        {db.db_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'Agents' && (
              <div>
                  <h3><strong>Agents Settings</strong></h3>
                  <div className="setting-grid">
                      <div className="setting-item">
                          <span>Use Agents</span>
                          <input
                              type="checkbox"
                              checked={useAgentAPI}
                              onChange={e => setUseAgentAPI(e.target.checked)}
                          />
                      </div>
                      <div className="setting-item">
                          <span>See Thoughts and Actions</span>
                          <input
                              type="checkbox"
                              checked={seeThoughtsAndActions}
                              onChange={(e) => setSeeThoughtsAndActions(e.target.checked)}
                          />
                      </div>
                      <div className="setting-item">
                          <span>Loops before Human Input:</span>
                          <input
                              type="number"
                              value={loopsBeforeHumanInput}
                              onChange={e => setLoopsBeforeHumanInput(parseInt(e.target.value, 10))}
                          />
                      </div>
                      <div className="setting-item">
                          <span>Select Agent Pipeline:</span>
                          <select value={selectedPipeline} onChange={(e) => setSelectedPipeline(e.target.value)}>
                              <option value="">--Select a Pipeline--</option>
                              {pipelines.map(pipeline => (
                                  <option key={pipeline.id} value={pipeline.name}>
                                      {pipeline.name}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>
          )}
          {activeTab === 'Prompts' && (
            <div>
              <h3>Select a System Prompt</h3>
              <select onChange={handleSelect} value={selectedPrompt?.id || ''}>
                <option value="">Select a prompt</option>
                {prompts.map(prompt => (
                  <option key={prompt.id} value={prompt.id}>
                    {prompt.prompt_name}
                  </option>
                ))}
              </select>
              {selectedPrompt && (
                <div>
                  <h3>Selected Prompt Details</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Prompt Name</th>
                        <th>Prompt Text</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedPrompt.prompt_name}</td>
                        <td>
                          <div style={{ maxWidth: '400px', display: 'flex', alignItems: 'center' }}>
                            <span>
                              {showFullText ? selectedPrompt.prompt_text : `${selectedPrompt.prompt_text.substring(0, 100)}...`}
                            </span>
                            {selectedPrompt.prompt_text.length > 100 && (
                              <button onClick={() => setShowFullText(!showFullText)}>
                                {showFullText ? 'Show Less' : 'Show More'}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {activeTab === 'Tools' && (
            <div>
              <h3>Select Agent Tools</h3>
              <div className="setting-grid">
                {Object.keys(selectedTools).map((tool) => (
                  <div className="setting-item" key={tool}>
                    <span>{tool.replace(/([A-Z])/g, ' $1')}:</span>
                    <input
                      type="checkbox"
                      checked={selectedTools[tool]}
                      onChange={() => handleToolChange(tool)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button onClick={handleSave}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ChatSettingsModal;