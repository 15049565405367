import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataReviewSidebar from './DataReviewSidebar';
import CombinedNetworkGraph from './CombinedNetworkGraph';
import './DataReview.css';
import Markdown from 'markdown-to-jsx';
import ColumnSelectionModal from './ColumnSelectionModal';
import * as XLSX from 'xlsx'; // Import the XLSX library here

// Legend component definition
const Legend = ({ legendData }) => (
  <div
    className="legend-container"
    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '10px' }}
  >
    {Object.entries(legendData).map(([fileName, color]) => (
      <div
        key={fileName}
        style={{ display: 'flex', alignItems: 'center', marginRight: '15px', marginBottom: '5px' }}
      >
        <div style={{
          width: '20px',
          height: '20px',
          backgroundColor: color,
          marginRight: '5px'
        }} />
        <span>{fileName}</span>
      </div>
    ))}
  </div>
);

const DataReview = () => {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [knGraphOption, setKnGraphOption] = useState('kn_graph_full');
  const [directories, setDirectories] = useState([]);
  const [selectedDirectory, setSelectedDirectory] = useState('');
  const [directoryFiles, setDirectoryFiles] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [graphDataSets, setGraphDataSets] = useState([]);
  const [legendData, setLegendData] = useState({});
  const [graphQuery, setGraphQuery] = useState('');
  const [tableData, setTableData] = useState([]);
  const [topEntries, setTopEntries] = useState({ terms: [], edge: [] });
  const [activeCollectionFiles, setActiveCollectionFiles] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(['id', 'file_name', 'key_concepts']);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summary, setSummary] = useState("");
  const [activeTab, setActiveTab] = useState('Select Columns');
  const [systemPrompts, setSystemPrompts] = useState([]);
  const [selectedSystemPrompt, setSelectedSystemPrompt] = useState('');
  const [generateExcel, setGenerateExcel] = useState(false); // New state for Excel generation
  const [summaryVisible, setSummaryVisible] = useState(false); // New state to control summary visibility
  const [tablesVisible, setTablesVisible] = useState(false); // New state to manage table visibility

  const [searchQuery, setSearchQuery] = useState(''); // Add searchQuery state
  const [searchResults, setSearchResults] = useState([]); // Add searchResults state
  const [fileCount, setFileCount] = useState(0); // Add fileCount state
  const [query, setQuery] = useState(''); 

  const baseURL = 'https://r3-pearl.ddns.net';

  useEffect(() => {
    axios.get(`${baseURL}/api/names`)
      .then((response) => setCollections(response.data))
      .catch((error) => console.error('Error fetching collections:', error));

    axios.get(`${baseURL}/api/directories`)
      .then((response) => setDirectories(response.data))
      .catch((error) => console.error('Error fetching directories:', error));

    const fetchPrompts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/prompts`);
        setSystemPrompts(response.data);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    };
    fetchPrompts();
  }, []);

  useEffect(() => {
    if (filesData.length > 0) {
      const columns = Object.keys(filesData[0]);
      setAvailableColumns(columns);
      setVisibleColumns(['id', 'file_name', 'key_concepts'].filter(column => columns.includes(column)));
    }
  }, [filesData]);

  const handleSearch = async () => {
    if (searchQuery) {
        try {
            const response = await axios.get(`https://r3-pearl.ddns.net/api/search_files?searchTerm=${encodeURIComponent(searchQuery)}`);
            setSearchResults(response.data.results);
            setFileCount(response.data.fileCount || 0);
            setQuery(response.data.query || 'No query generated');

            // Automatically add search results to filesData in DataReview
            toggleFileInList(response.data.results, true);

        } catch (error) {
            console.error('Error searching files:', error);
        }
    } else {
        setSearchResults([]);
        setFileCount(0);
        setQuery('No query generated');
    }
};

  const fetchSummary = async () => {
    try {
        // Retrieve the token from local storage or state
        const token = localStorage.getItem('token'); // Assuming you store the token after login

        const jsonDataToSummarize = filesData
            .map(file => file[knGraphOption])
            .filter(data => data); // Ensure data is present

        if (jsonDataToSummarize.length === 0) {
            throw new Error('No data available for the selected graph option.');
        }

        const systemPrompt = selectedSystemPrompt || "Act as a Natural Language Expert. Review the following and provide a verbose, detailed, and structured explanation of this information. DO NOT DISCUSS THE DATA STRUCTURE JUST PROVIDE THE ANALYSIS";

        let statements = [];
        let statementCount = 1;

        jsonDataToSummarize.forEach((dataStr, index) => {
            try {
                const dataArray = JSON.parse(dataStr);
                const fileName = filesData[index].file_name; // Get the associated file name

                dataArray.forEach(item => {
                    const { node_1, edge, node_2 } = item;
                    const statement = `${statementCount}. ${node_1} ${edge} ${node_2} (${fileName})`; // Append file name
                    statements.push(statement);
                    statementCount++;
                });
            } catch (parseError) {
                console.error('Error parsing data string:', parseError);
            }
        });

        //Only keep the first 1000 statements
        const limitedStatements = statements.slice(0,1500);
        
        // Join statements into a single userContent string
        // const userContent = statements.join('\\n');
        const userContent = limitedStatements.join('\\n');

        // if (generateExcel) {
        //       const excelData = statements.map((statement, index) => {
        //           return { 
        //               Statement: statement, 
        //               Data: filesData[index] // Assuming corresponding index match, adjust if necessary
        //           };
        //       });
  
        //       const workbook = XLSX.utils.book_new();
        //       const worksheet = XLSX.utils.json_to_sheet(excelData);
        //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
        //       XLSX.writeFile(workbook, './outputs/userContentSummary.xlsx'); // Specify the output path and filename
        //   }

        // Make sure to send the token in headers
        const response = await axios.post(`${baseURL}/api/prochat`, {
            messages: [
                { role: 'system', content: systemPrompt },
                { role: 'user', content: userContent },
            ],
        }, {
            headers: {
                'Authorization': `Bearer ${token}` // Add token to headers
            },
            timeout: 300000 // Set timeout to 5 minutes (300000 ms)
        });

        if (response.data && response.data.choices && response.data.choices[0].message) {
            setSummary(response.data.choices[0].message.content);
        }
        if (generateExcel) {
            const excelData = statements.map((statement, index) => {
                return { 
                    Statement: statement, 
                    Data: filesData[index] // Assuming corresponding index match, adjust if necessary
                };
            });

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(excelData);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
            XLSX.writeFile(workbook, './outputs/userContentSummary.xlsx'); // Specify the output path and filename
        }

    } catch (error) {
        console.error("Error fetching summary from chat API:", error);
    }
    setSummaryVisible(true); // Show summary after it is generated
};

  const handleExcelToggle = () => {
    setGenerateExcel(!generateExcel);
  };

  const handleCollectionChange = (name) => {
    if (name === selectedCollection) {
      setSelectedCollection('');
      resetGraphData();
      setTablesVisible(false); // Hide tables when selection is cleared
      return;
    }

    setSelectedCollection(name);

    axios.get(`${baseURL}/api/files/${name}`)
      .then((response) => {
        if (!Array.isArray(response.data)) {
          throw new Error('File IDs response is not an array');
        }

        setActiveCollectionFiles(response.data);
        return axios.post(`${baseURL}/api/query-datatable`, { selectedIds: response.data });
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setFilesData([...response.data]); // Reset the filesData with new selection
          setTablesVisible(true); // Show tables when files are selected
          processCombinedKGData(response.data); // Process data to extract top entries
        } else {
          console.error('Unexpected response format:', response.data);
          throw new Error('Query did not return an array of data');
        }
      })
      .catch((error) => {
        console.error('Error fetching files or querying articles:', error);
      });
  };  

  const handleDirectorySelection = (directory) => {
    setSelectedDirectory(directory);

    axios.get(`${baseURL}/api/files-in-directory/${directory}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDirectoryFiles(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching files from directory:', error);
      });
  };

  // const toggleFileInList = (file, selectAll = false) => {
  //   setFilesData((prevFiles) => {
  //     if (selectAll) {
  //       const newFiles = Array.from(new Set([...prevFiles, ...file]));
  //       processCombinedKGData(newFiles);
  //       return newFiles;
  //     } else {
  //       const fileExists = prevFiles.find(f => f.id === file.id);
  //       const updatedFiles = fileExists ? prevFiles.filter(f => f.id !== file.id) : [...prevFiles, file];
  //       processCombinedKGData(updatedFiles);
  //       return updatedFiles;
  //     }
  //   });
  // };

  const toggleFileInList = (file, selectAll = false) => {
    setFilesData((prevFiles) => {
      if (selectAll) {
        const newFiles = Array.from(new Set([...prevFiles, ...file])); 
        processCombinedKGData(newFiles); // Ensures tableData is populated 
        setTablesVisible(newFiles.length > 0); // Toggles table visibility based on file list
        return newFiles;
      } else {
        const fileExists = prevFiles.find(f => f.id === file.id);
        const updatedFiles = fileExists ? prevFiles.filter(f => f.id !== file.id) : [...prevFiles, file];
        processCombinedKGData(updatedFiles);
        setTablesVisible(updatedFiles.length > 0); // Toggles table visibility based on file list
        return updatedFiles;
      }
    });
  };

  const handleClearFiles = () => {
    setFilesData([]);
    setActiveCollectionFiles([]);
    setSelectedCollection('');
    setDirectoryFiles([]);
    setSelectedDirectory('');
    resetGraphData();
    setSummary('');
    setTopEntries({ terms: [], edge: [] }); // Reset the top entries
  };

  const handleCreateCollection = () => {
    if (!collectionName) {
      alert('Collection Name is required');
      return;
    }

    const files = filesData.map(file => file.id);

    axios.post(`${baseURL}/api/create-collection`, {
      name: collectionName,
      description: collectionDescription,
      files: files
    })
    .then(() => {
      alert('Collection created successfully');
      setCollectionName('');
      setCollectionDescription('');
      handleClearFiles();
    })
    .catch((error) => {
      console.error('Error creating collection:', error);
      alert('Failed to create the collection');
    });
  };

  const handleCreateKnowledgeGraph = () => {
    try {
      resetGraphData();

      const legend = {};

      const graphDataSets = filesData.map((file, index) => {
        const queryResults = graphQuery ? refineGraphData(file[knGraphOption], graphQuery) : file[knGraphOption];

        if (queryResults) {
          const color = `hsl(${index * 137.508}, 50%, 50%)`;
          legend[file.file_name] = color;
          return {
            nodesData: structureGraphData(queryResults).nodes.map(node => ({ ...node, color })),
            edgesData: structureGraphData(queryResults).edges,
            fileName: file.file_name
          };
        }
        return null;
      }).filter(Boolean);

      setLegendData(legend);
      setGraphDataSets(graphDataSets);

    } catch (error) {
      console.error('Error preparing knowledge graph:', error);
      alert('Failed to prepare the knowledge graph');
    }
  };

  const structureGraphData = (rawData) => {
    try {
      const jsonData = JSON.parse(rawData);
      const nodes = [];
      const edges = [];

      jsonData.forEach(item => {
        nodes.push({ id: item.node_1, label: item.node_1 });
        nodes.push({ id: item.node_2, label: item.node_2 });
        edges.push({ from: item.node_1, to: item.node_2, label: item.edge });
      });

      return { nodes, edges };
    } catch (e) {
      console.error('Failed to parse graph data:', e);
      return { nodes: [], edges: [] };
    }
  };

  const processCombinedKGData = (data) => {
    let combinedData = [];
    data.forEach(fileData => {
      try {
        const jsonData = JSON.parse(fileData[knGraphOption]);
        combinedData = [...combinedData, ...jsonData];
      } catch (error) {
        console.error('Error parsing file data to JSON', error);
      }
    });

    const termCounts = {};
    const edgeCounts = {};

    combinedData.forEach(item => {
      const { node_1, node_2, edge } = item;
      if (node_1 && node_2 && edge) {
        termCounts[node_1] = (termCounts[node_1] || 0) + 1;
        termCounts[node_2] = (termCounts[node_2] || 0) + 1;
        edgeCounts[edge] = (edgeCounts[edge] || 0) + 1;
      }
    });

    const topTerms = Object.entries(termCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10);

    const topEdges = Object.entries(edgeCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5);

    setTableData(combinedData);

    setTopEntries({
      terms: topTerms,
      edge: topEdges
    });
  };

  const refineGraphData = (graphData, query) => {
    try {
      let results = JSON.parse(graphData);
      const queries = query.split(',').map(q => q.trim().toLowerCase());

      results = results.filter(item => {
        return queries.some(q =>
          item.node_1.toLowerCase().includes(q) ||
          item.node_2.toLowerCase().includes(q) ||
          item.edge.toLowerCase().includes(q)
        );
      });

      return JSON.stringify(results);
    } catch (error) {
      console.error('Error refining graph data:', error);
      return graphData;
    }
  };

  const resetGraphData = () => {
    setGraphDataSets([]);
    setLegendData({});
  };

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleColumnToggle = (column) => {
    setVisibleColumns((currentColumns) => {
      if (column === 'id' || column === 'file_name' || column === 'key_concepts') return currentColumns;
      return currentColumns.includes(column)
        ? currentColumns.filter(col => col !== column)
        : [...currentColumns, column];
    });
  };

  // const displayTopEntriesTable = () => {
  //   return (
  //     <div className="top-entries-table">
  //       <h3>Top Entries</h3>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>Type</th>
  //             <th>Node/Edge</th>
  //             <th>Count</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {['terms', 'edge'].map((type) =>
  //             topEntries[type].map(([node, count]) => (
  //               <tr key={`${type}-${node}`}>
  //                 <td>{type}</td>
  //                 <td>{node}</td>
  //                 <td>{count}</td>
  //               </tr>
  //             ))
  //           )}
  //         </tbody>
  //       </table>
  //     </div>
  //   );
  // };

  return (
    <div className="data-review-container">
      <DataReviewSidebar
        collections={collections}
        selectedCollection={selectedCollection}
        setSelectedCollection={handleCollectionChange}
        knGraphOption={knGraphOption}
        setKnGraphOption={(value) => setKnGraphOption(value)}
        directories={directories}
        selectedDirectory={selectedDirectory}
        handleDirectorySelection={handleDirectorySelection}
        directoryFiles={directoryFiles}
        toggleFileInList={toggleFileInList}
        files={filesData}

      />
      <div className="table-wrapper">
        <h2>Data Review</h2>
        <p>Here you can review and analyze data from the selected collections and files.</p>

        <input
          type="text"
          className="uniform-input"
          placeholder="Enter Graph Query (comma-separated)"
          value={graphQuery}
          onChange={(e) => setGraphQuery(e.target.value)}
        />

        <div className="button-container">
          <button onClick={handleClearFiles}>Clear Selected Files</button>
          <button onClick={handleCreateCollection}>Create Collection</button>
          <button onClick={handleCreateKnowledgeGraph}>Create Knowledge Graph</button>
          <button onClick={fetchSummary}>Generate Summary</button>
          <label>
            <input
              type="checkbox"
              checked={generateExcel}
              onChange={handleExcelToggle} // Toggle Excel generation
            />
            Output Excel File
          </label>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
          <input
            type="text"
            className="uniform-input"
            placeholder="Collection Name"
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
          />
          <textarea
            className="uniform-input"
            placeholder="Collection Description"
            value={collectionDescription}
            onChange={(e) => setCollectionDescription(e.target.value)}
          />
        </div>

        <button onClick={handleModalOpen}>Review Settings</button>
        {isModalOpen && (
          <ColumnSelectionModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            systemPrompts={systemPrompts}
            selectedSystemPrompt={selectedSystemPrompt}
            setSelectedSystemPrompt={setSelectedSystemPrompt}
            columns={availableColumns}
            onToggleColumn={handleColumnToggle}
          />
        )}

        {graphDataSets.length > 0 && (
          <div>
            <Legend legendData={legendData} />
            <CombinedNetworkGraph fileDataSets={graphDataSets} />
          </div>
        )}
        {/* Display summary only after it is generated */}
        {summaryVisible && (
          <div className="summary-section">
            <h3>Generated Summary</h3>
            <Markdown>{summary}</Markdown>
          </div>
        )}

        {/* Display the data table only if files are selected */}
        {tablesVisible && (
          <table>
            <thead>
              <tr>
                {visibleColumns.map(column => <th key={column}>{column}</th>)}
              </tr>
            </thead>
            <tbody>
              {filesData.map(file => (
                <tr key={file.id}>
                  {visibleColumns.map(column => <td key={column}>{file[column]}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DataReview;